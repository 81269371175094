










import {Component, Vue} from "vue-property-decorator";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import UserDTO from "@/dto/UserDTO";
import {excelAccept} from "@/constants/FileConstants";
import FileUpload from "@/components/common/FileUpload.vue";
import {namespace} from "vuex-class";
import CompanyService from "@/services/CompanyService";
import EmployeeService from "@/services/EmploymentService";

const Auth = namespace("Auth");
const AppModule = namespace("App");

@Component({
  components: {FileUpload}
})
export default class EmployeesUpload extends Vue {

  @AppModule.State
  private loading!: boolean;

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Auth.State
  private user!: UserDTO

  private successful = false;

  private message = "";

  private DOCUMENT_ACCEPT = excelAccept;

  onFilesUploaded(files: FileMetaDTO[]) {
    this.message = "";
    this.startLoading();
    EmployeeService.batchUpload(files[0].id).then(
        success => {
          this.successful = true;
          this.message = "Uploaded";
        },
        error => {
          this.successful = false;
          this.message = "Error"
        }
    ).then(
        () => {
          this.stopLoading();
        }
    );
  }

}
